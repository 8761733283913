import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'ev-scroll-top-button',
  templateUrl: './scroll-top-button.component.html',
  styleUrls: ['./scroll-top-button.component.scss'],
})
export class ScrollTopButtonComponent implements AfterViewInit {
  public showButton = false;

  private get mainDiv(): Element | null | undefined {
    return document.getElementById('wrapper');
  }

  ngAfterViewInit(): void {
    // The mainDiv element gets rendered with a bit of delay, that's why we need to wait
    setTimeout(() => {
      if (!this.mainDiv) {
        throw new Error(
          '[ScrollTopButtonComponent::ngAfterViewInit] No atom-shell .page is available',
        );
      }
      this.mainDiv?.addEventListener('scroll', () => this.calcShowButton());
    }, 1000);
  }

  public onScrollTop(): void {
    this.mainDiv?.scrollTo(0, 0);
  }

  private calcShowButton(): void {
    const documentLocation = this.mainDiv?.scrollTop;
    this.showButton = documentLocation !== 0;
  }
}
