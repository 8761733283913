import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'ev-lined-accordion',
  templateUrl: './lined-accordion.component.html',
  styleUrls: ['./lined-accordion.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LinedAccordionComponent {
  /**
   * Input and output event as well, to control and listen to the open state of the accordion
   */
  @Input() isOpened = true;

  @Input() iconClasses = '';
  /**
   * Emitting a boolean value, when the accordion gets opened or closed
   */
  @Output() isOpenedChange = new EventEmitter<boolean>();

  public onIsOpenedChange(): void {
    this.isOpened = !this.isOpened;
    this.isOpenedChange.emit(this.isOpened);
  }
}
