import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[evTableHeader]',
  standalone: true,
})
export class TableHeaderDirective {
  elementStyles: Record<string, string> = {
    background: '#373737',
    color: '#fff',
    'font-weight': '700',
    'font-size': '14px',
    'line-height': '18px',
    padding: '8px 24px',
  };

  constructor(private elementRef: ElementRef) {
    Object.assign(this.elementRef.nativeElement.style, this.elementStyles);
  }
}
