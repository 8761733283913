<div
  class="quantity-selector"
  [ngClass]="[size, showButtons ? 'with-buttons' : '']">
  @if (showButtons) {
    <div
      class="quantity-button"
      [ngClass]="{
        disabled: quantityFormControl.value && quantityFormControl.value <= 1,
      }"
      (click)="onDecreaseQuantity()">
      -
    </div>
  }
  <input
    type="text"
    class="ev-input quantity-input"
    [ngClass]="[size, quantityFormControl.invalid ? 'invalid' : '']"
    [formControl]="quantityFormControl" />
  @if (showButtons) {
    <div class="quantity-button" (click)="onIncreaseQuantity()">+</div>
  }
  @if (unit) {
    <div class="unit ml-1">{{ unit }}</div>
  }
</div>
