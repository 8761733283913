<div class="ev-overlay" (click)="onOverlayClick($event)">
  <div
    class="dialog"
    [ngClass]="{ stretch: $stretchMode() }"
    data-cy="dialog-box">
    <div class="header flex justify-between gap-8 mb-4">
      <div class="titles">
        @if ($title()) {
          <div class="title mb-2">{{ $title()! }}</div>
        }
        @if ($subtitle()) {
          <div class="subtitle mr-2 mb-2" [innerHtml]="$subtitle()!"></div>
        }
      </div>
      @if (!$hideCloseButton()) {
        <div
          class="close-icon-container cursor-pointer select-none"
          (click)="onClose()">
          <atom-icon class="close-icon" icon-id="atom:core:close"></atom-icon>
        </div>
      }
    </div>
    <div class="content mx-2">
      <ng-content></ng-content>
    </div>
  </div>
</div>
