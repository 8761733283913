import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { ModalDialogComponent } from '../modal-dialog';

@Component({
  selector: 'ev-confirm-dialog',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, ModalDialogComponent],
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() confirmButtonText = $localize`Confirm`;
  @Output() closeDialog = new EventEmitter<void>();
  @Output() confirmDialog = new EventEmitter<void>();

  public onClose(): void {
    this.closeDialog.emit();
  }

  public onConfirm(): void {
    this.confirmDialog.emit();
  }
}
