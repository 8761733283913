import { Directive, ElementRef, inject, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[evShimmerEffect]',
})
export class ShimmerEffectDirective implements OnChanges {
  private elementRef = inject(ElementRef);

  private shimmerDiv: HTMLDivElement;

  @Input() evShimmerEffect: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    this.init();

    if (changes['evShimmerEffect']?.currentValue) {
      this.shimmerDiv.style.display = 'block';
      this.elementRef.nativeElement.style.pointerEvents = 'none';
    } else {
      this.shimmerDiv.style.display = 'none';
      this.elementRef.nativeElement.style.pointerEvents = 'auto';
    }
  }

  private initShimmerDiv(): void {
    if (this.shimmerDiv) {
      return;
    }

    // Creat and add the shimmer div
    this.shimmerDiv = document.createElement('div');
    this.shimmerDiv.classList.add('shimmer');
    this.shimmerDiv.style.display = 'none';
    this.elementRef.nativeElement.prepend(this.shimmerDiv);
  }

  private init(): void {
    this.elementRef.nativeElement.style.position = 'relative';
    this.initShimmerDiv();
    this.initStyles();
  }

  private initStyles(): void {
    const existingStyles = document.querySelector('#shimmerEffectStyles');
    if (existingStyles) {
      return;
    }

    const shimmerEffectStyles = document.createElement('style');
    shimmerEffectStyles.id = 'shimmerEffectStyles';
    document.head.appendChild(shimmerEffectStyles);

    // Insert animation rules
    shimmerEffectStyles.sheet?.insertRule(`
      @-webkit-keyframes placeholderShimmer {
        0% {
          background-position: -468px 0;
        }

        100% {
          background-position: 468px 0;
        }
      }
    `);

    const BASE_COLOR = '#DFDFDF'; //'#f6f7f8';
    const SHIMMER_COLOR = '#edeef1';

    // Insert shimmer class rules, box-sizing and padding added to cater for all letters sizes
    shimmerEffectStyles.sheet?.insertRule(`
      .shimmer {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;

        background: ${BASE_COLOR};
        background-image: linear-gradient(to right, ${BASE_COLOR} 0%, ${SHIMMER_COLOR} 20%, ${BASE_COLOR} 40%, ${BASE_COLOR} 100%);
        background-repeat: no-repeat;
        background-size: 800px 104px;
        display: inline-block;
        padding: 1px;
        box-sizing: content-box;

        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-name: placeholderShimmer;
        -webkit-animation-timing-function: linear;
      }
    `);
  }
}
