import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { AngularModule } from '@atoms/angular';

@Component({
  standalone: true,
  imports: [CommonModule, AngularModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'ev-chip',
  templateUrl: 'chip.component.html',
  styleUrls: ['chip.component.scss'],
})
export class ChipComponent {
  @Input() content: string;
  @Input() hideCloseIcon = false;
}
