import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[evColumnLayout]',
  standalone: true,
})
export class ColumnLayoutDirective implements OnInit {
  @Input() evColumnLayout: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    const elementStyles: Record<string, string> = {};
    elementStyles['grid-template-columns'] = this.evColumnLayout;

    Object.assign(this.elementRef.nativeElement.style, elementStyles);
  }
}
