import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AngularModule } from '@atoms/angular';

@Component({
  standalone: true,
  imports: [CommonModule, AngularModule, ReactiveFormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'ev-time-range-selector',
  templateUrl: './time-range-selector.component.html',
  styleUrls: ['./time-range-selector.component.scss'],
})
export class TimeRangeSelectorComponent {
  @Input() formGroup: FormGroup<TimeRangeFormGroup>;
  @Input() label?: string;

  timeOptions = timeOptions;
}

const timeOptions: string[] = [];
for (let hour = 0; hour <= 23; hour++) {
  for (let minute = 0; minute <= 30; minute += 30) {
    const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    timeOptions.push(time);
  }
}

export interface TimeRangeFormGroup {
  timeFrom: FormControl<string | null>;
  timeTo: FormControl<string | null>;
}
