import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output } from '@angular/core';

/**
 * Design is based on:
 * https://www.figma.com/file/HgO5Ank9S49rm3ua4HV8O4/Digital-Commerce-Platform---UI-Library?node-id=391%3A1073&t=jkT2HGMsZTZB3Yw4-0
 */
@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'ev-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NotificationComponent {
  @Input() type: 'error' | 'warning' | 'info' | 'success' = 'error';
  @Input() title: string;
  @Input() closable = false;

  @Output() closeNotification: EventEmitter<void> = new EventEmitter();

  public onClose(): void {
    this.closeNotification.emit();
  }
}
