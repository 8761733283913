import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, input, output } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'ev-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent {
  $title = input<string>();
  $subtitle = input<string>();
  $stretchMode = input(false);
  $hideCloseButton = input(false);
  $loading = input(false);
  closeDialog = output<void>();

  // Check, that we really clicked just on the overlay.
  onOverlayClick(event: MouseEvent): void {
    if (this.$hideCloseButton()) {
      return;
    }

    const eventTarget = event.target as HTMLElement;
    if (eventTarget.classList.contains('overlay')) {
      this.onClose();
    }
  }

  public onClose(): void {
    this.closeDialog.emit();
  }
}
