import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
@Component({
  standalone: true,
  selector: 'ev-back-link',
  template: `
    <div class="back-link-container flex items-center cursor-pointer select-none">
      <atom-icon class="back-link-icon mr-1" icon-id="atom:core:arrow_back"></atom-icon>
      <a> {{ text }} </a>
    </div>
  `,
  styleUrls: ['./back-link.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BackLinkComponent {
  public backLinkText = $localize`Go back`;
  @Input() text = this.backLinkText;
}
