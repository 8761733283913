// Docs page: https://atoms.docs.net/atom
export async function loadBasfAtoms(): Promise<void> {
  applyShadowDomFixes();
  await loadIcons();

  import('@atoms/alert');
  import('@atoms/breadcrumb');
  import('@atoms/button');
  import('@atoms/segmented-buttons');
  import('@atoms/checkbox');
  import('@atoms/dropdown');
  import('@atoms/footer');
  import('@atoms/header');
  import('@atoms/navigation');
  import('@atoms/progress-spinner');
  import('@atoms/radio');
  import('@atoms/shell');
  import('@atoms/tabs');
  import('@atoms/textarea');
  import('@atoms/tooltip');
  import('@atoms/stepper');
  import('@atoms/input-v2');
  import('@atoms/dropdown-v2');
  import('@atoms/file-upload-v2');
}

async function loadIcons(): Promise<void> {
  const iconsetsPromise = Promise.all([
    import('@atoms/iconset-basf'),
    import('@atoms/iconset-core'),
    import('@atoms/iconset-mat-action'),
    import('@atoms/iconset-mat-file'),
    import('@atoms/iconset-mat-editor'),
    import('@atoms/iconset-mat-maps'),
  ]);
  const iconsets = await iconsetsPromise;
  const { registerIconset } = await import('@atoms/icon');
  iconsets.forEach(iconsetModule => {
    registerIconset(iconsetModule.definition);
  });
}

function applyShadowDomFixes(): void {
  // Select the target node
  const targetNode = document.querySelector('body') as Element;

  // Listen to added DOM elements
  const observer = new MutationObserver((mutationsList, observer) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        mutation.addedNodes.forEach(node => {
          fixAtomTooltip(node);
        });
      }
    }
  });

  // Configure and start observing the target node for mutations
  const observerConfig = {
    childList: true, // Watch for changes in the children of the target node
    subtree: true, // Watch for changes in all descendants of the target node
  };

  observer.observe(targetNode, observerConfig);
}

// Fix: add z-index=1 to <atom-tooltip> within the shadowRoot
function fixAtomTooltip(node: Node): void {
  if (node.nodeName === 'ATOM-TOOLTIP') {
    const tooltip: HTMLElement | null =
      (node as HTMLElement)?.shadowRoot?.querySelector('.tooltip') ?? null;
    if (tooltip) {
      tooltip.style.zIndex = '1';
    }
  }
}
