import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AngularModule } from '@atoms/angular';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AngularModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'ev-quantity-selector',
  templateUrl: './quantity-selector.component.html',
  styleUrls: ['./quantity-selector.component.scss'],
})
export class QuantitySelectorComponent {
  @Input() quantityFormControl: FormControl<number | null>;
  @Input() size: Size = 'medium';
  @Input() showButtons = true;
  @Input() unit?: string;

  private minValue = 1;

  public onIncreaseQuantity(): void {
    if (!Number.isInteger(Number(this.quantityFormControl.value))) {
      this.quantityFormControl.patchValue(this.minValue);

      return;
    }

    this.quantityFormControl.patchValue(
      Math.max(this.minValue, Number(this.quantityFormControl.value) + 1),
    );
  }

  public onDecreaseQuantity(): void {
    if (!Number.isInteger(Number(this.quantityFormControl.value))) {
      this.quantityFormControl.patchValue(this.minValue);

      return;
    }

    this.quantityFormControl.patchValue(
      Math.max(this.minValue, Number(this.quantityFormControl.value) - 1),
    );
  }
}

type Size = 'small' | 'medium' | 'large';
