<h1 class="error-title">
  <span>{{ status }}</span>
</h1>
<p class="subtitle" [innerHTML]="statusText"></p>
<p class="subtitle" [innerHTML]="statusDescription"></p>

<p class="heading" i18n>Here are some other useful pages</p>
<ol>
  @for (item of actionItems; track item) {
    <li item="item">
      <a (click)="onClick(item.key)" class="cursor-pointer"
        ><atom-icon iconId="atom:basf:link_circle"></atom-icon> {{ item.name }}
      </a>
    </li>
  }
</ol>

<ng-content></ng-content>
