import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { PaginatorComponent } from '../paginator';

@Component({
  standalone: true,
  imports: [CommonModule, PaginatorComponent],
  selector: 'ev-table-footer',
  templateUrl: './table-footer.component.html',
  styleUrls: ['./table-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableFooterComponent {
  @Input() totalItems: number;
  @Input() itemsPerPage: number;
  @Input() activePageIndex: number;

  @Output() activatePage = new EventEmitter<number>();

  public get totalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  public get visibleItemsStart(): number {
    return this.activePageIndex * this.itemsPerPage + 1;
  }

  public get visibleItemsEnd(): number {
    return Math.min((this.activePageIndex + 1) * this.itemsPerPage, this.totalItems);
  }
}
