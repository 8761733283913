import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, forwardRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { dateRangeValidator } from '@ev-portals/ev/frontend/util';
import { CalendarModule } from 'primeng/calendar';
import { filter } from 'rxjs';

export interface DateRange {
  from: Date;
  to: Date;
}

type DateRangeChangeFn = (dateRange?: DateRange) => void;

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, CalendarModule],
  selector: 'ev-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeSelectorComponent),
      multi: true,
    },
  ],
})
export class DateRangeSelectorComponent implements ControlValueAccessor, AfterViewInit {
  private readonly formBuilder = inject(FormBuilder);
  private readonly destroyRef = inject(DestroyRef);

  private onChange?: DateRangeChangeFn;
  private onTouched?: DateRangeChangeFn;

  readonly formGroup = this.formBuilder.group(
    {
      from: new FormControl<Date | undefined>(undefined),
      to: new FormControl<Date | undefined>(undefined),
    },
    {
      validators: dateRangeValidator(),
    },
  );

  readonly minDate$ = this.formGroup.get('from')!.valueChanges;
  readonly maxDate$ = this.formGroup.get('to')!.valueChanges;

  ngAfterViewInit(): void {
    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(dateRange => {
        this.onChange?.(dateRange as DateRange);
      });
  }

  writeValue(dateRange?: DateRange): void {
    if (dateRange) {
      this.formGroup.patchValue({
        from: dateRange.from,
        to: dateRange.to,
      });
    } else {
      this.formGroup.reset();
    }
  }

  registerOnChange(fn: DateRangeChangeFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: DateRangeChangeFn): void {
    this.onTouched = fn;
  }
}
