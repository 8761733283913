import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'ev-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
  @Input() status: string;
  @Input() statusText: string;
  @Input() statusDescription: string;
  @Input() actionItems: { name: string; key: string }[] = [];
  @Output() actionItemClick = new EventEmitter<string>();

  public onClick(value: string): void {
    this.actionItemClick.emit(value);
  }
}
