import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularModule } from '@atoms/angular';

@Component({
  standalone: true,
  imports: [CommonModule, AngularModule, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'ev-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent {
  // Array index, page 1 equals to activePageIndex 0
  @Input() activePageIndex = 0;
  @Input() numberOfPages = 0;
  @Output() activatePage = new EventEmitter<number>();

  public paginatorPlaceholder = $localize`Select`;
  public onActivatePage(newPageIndex: number): void {
    // Drop invalid ranges without effect
    if (newPageIndex < 0 || newPageIndex >= this.numberOfPages) {
      return;
    }

    this.activePageIndex = newPageIndex;
    this.activatePage.emit(this.activePageIndex);
  }

  public onDropdownChange(): void {
    this.activatePage.emit(this.activePageIndex);
  }
}
