import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { ModalDialogComponent } from '../modal-dialog';

@Component({
  selector: 'ev-alert-dialog',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, ModalDialogComponent],
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDialogComponent {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() okButtonText = $localize`OK`;
  @Output() closeDialog = new EventEmitter<void>();

  public onClose(): void {
    this.closeDialog.emit();
  }
}
