@if (formGroup) {
  <div class="flex flex-col w-full gap-1">
    <div [formGroup]="formGroup" class="flex gap-2 items-center w-full">
      <p-calendar
        class="w-full"
        styleClass="w-full"
        data-cy="date-range-from"
        [maxDate]="maxDate$ | async"
        [readonlyInput]="true"
        formControlName="from" />
      <div>-</div>
      <p-calendar
        class="w-full"
        styleClass="w-full"
        data-cy="date-range-to"
        [minDate]="minDate$ | async"
        [readonlyInput]="true"
        formControlName="to" />
    </div>
    @if (formGroup.invalid) {
      <div class="text-warn" i18n>
        From Date can not be later than To Date
      </div>
    }
  </div>
}
