import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormControlOptions,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
} from '@angular/forms';
import { AngularModule } from '@atoms/angular';

import { ModalDialogComponent } from '../modal-dialog';

@Component({
  selector: 'ev-prompt-dialog',
  standalone: true,
  imports: [CommonModule, ModalDialogComponent, FormsModule, AngularModule, ReactiveFormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss'],
})
export class PromptDialogComponent implements AfterViewInit, OnInit {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() label? = '';
  @Input() placeholder = $localize`Type here...`;
  @Input() submitButtonText = $localize`OK`;
  @Input() errorMessage = $localize`Please don't use any special characters`;
  @Input() validators?: ValidatorFn | ValidatorFn[] | FormControlOptions | null;
  @Output() closeDialog = new EventEmitter<void>();
  @Output() submitDialog = new EventEmitter<string>();
  @ViewChild('promptInputField') promptInputField: ElementRef<HTMLInputElement>;
  public promptForm: FormGroup;

  readonly cancelButtonText = $localize`Cancel`;

  public ngOnInit(): void {
    this.promptForm = new FormGroup({
      promptInput: new FormControl('', this.validators),
    });
  }

  public ngAfterViewInit(): void {
    // without setTimeout we have a racing condition and focus won't work
    setTimeout(() => {
      this.promptInputField.nativeElement.focus();
    }, 0);
  }

  public onClose(): void {
    this.closeDialog.emit();
  }

  public onSubmit(): void {
    this.submitDialog.emit(this.promptForm.value.promptInput);
  }
}
