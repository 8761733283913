export type Theme = 'dark-blue' | 'dark-green' | 'light-green' | 'orange' | 'light-blue' | 'red';

export const themes: Theme[] = [
  'dark-blue',
  'dark-green',
  'light-green',
  'orange',
  'light-blue',
  'red',
];

export function selectTheme(themeName: Theme): void {
  // TODO: use a fixed version for prod instead of latest
  const atomsLibVersion = 'latest';
  document
    ?.getElementById('atoms-theme')
    ?.setAttribute(
      'href',
      `https://atoms.azureedge.net/atoms/lib/${atomsLibVersion}/themes/${themeName}.css`,
    );
}

export function selectEvTheme(): void {
  // TODO: switch random Theme with the selected one
  const randomTheme = themes[Math.floor(Math.random() * themes.length)];
  selectTheme(randomTheme);
}

export function selectDarkGreenTheme(): void {
  selectTheme('dark-green');
}
