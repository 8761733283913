<h1>&#64;atoms project showcase</h1>

## Atoms and molecules we need

<br />

<h2>➡ Textarea</h2>
<div class="note success">Works fine</div>
<atom-textarea
  label="Please give some info"
  placeholder="Start typing..."></atom-textarea>

<h2>➡ Theme picker (runtime)</h2>
<div class="note success">Works fine</div>
<atom-button (click)="onChangeTheme()">Change Theme</atom-button>
<br />
<div class="color-box primary-color">Primary</div>
<div class="color-box secondary-color">Secondary</div>
<div class="color-box pale-color">Pale</div>
<div class="color-box hover-color">Hover</div>
<br />
<br />
<div class="color-row color100">$color100</div>
<div class="color-row color200">$color200</div>
<div class="color-row color300">$color300</div>
<div class="color-row color400">$color400</div>
<div class="color-row color500">$color500</div>
<div class="color-row color600">$color600</div>
<div class="color-row color700">$color700</div>
<div class="color-row color800">$color800</div>
<div class="color-row color900">$color900</div>
<br />
<div class="color-row gray075">$gray075</div>
<div class="color-row gray100">$gray100</div>
<div class="color-row gray175">$gray175</div>
<div class="color-row gray200">$gray200</div>
<div class="color-row gray300">$gray300</div>
<div class="color-row gray400">$gray400</div>
<div class="color-row gray425">$gray425</div>
<div class="color-row gray500">$gray500</div>
<div class="color-row gray600">$gray600</div>
<div class="color-row gray700">$gray700</div>
<div class="color-row gray800">$gray800</div>
<div class="color-row gray900">$gray900</div>

<h2>➡ Tabs</h2>
<div class="note success">Looks good</div>
<atom-tabs>
  <section slot="tab">Tab 1</section>
  <section slot="panel">Panel 1 content</section>
  <section slot="tab">Tab 2</section>
  <section slot="panel">Panel 2 content</section>
</atom-tabs>

<h2>➡ Header</h2>
<div class="note success">Looks good</div>
<atom-shell
  style="--atoms-header-background: var(--atoms-basf-gray-075)"
  navigationTop>
  <atom-header slot="header" title="Page Title">
    <div
      style="display: flex; height: 100%; align-items: center; font-size: 1rem">
      <atom-header-item
        icon="atom:core:user"
        text="John Doe"></atom-header-item>
      <atom-header-item
        icon="atom:core:settings"
        style="margin-left: 2rem"></atom-header-item>
      <atom-header-item
        icon="atom:core:upload"
        style="transform: rotate(90deg); margin-left: 2rem"></atom-header-item>
    </div>
    <div slot="co-creation-logo" style="display: flex; align-items: center">
      <img
        style="height: 2rem"
        ngSrc="https://www.carecreations.basf.com/App_Img/logo_carecreations.svg"
        width="50"
        height="50" />
    </div>
  </atom-header>
  <atom-navigation id="navigation" slot="navigation">
    <atom-navigation-item>
      <span class="link" slot="title">Shopping cart</span>
      <span class="link" slot="icon" style="height: 1rem"
        ><atom-icon icon-id="atom:basf:cart"></atom-icon
      ></span>
      <atom-sub-navigation-item
        ><span class="link">Navigation item</span></atom-sub-navigation-item
      >
      <atom-sub-navigation-item
        ><span class="link">Navigation item 2</span></atom-sub-navigation-item
      >
    </atom-navigation-item>
    <atom-navigation-item>
      <span class="link" slot="title">Account</span>
      <span class="link" slot="icon" style="height: 1rem"
        ><atom-icon icon-id="atom:core:user"></atom-icon
      ></span>
      <atom-sub-navigation-item
        ><span class="link">Settings</span></atom-sub-navigation-item
      >
      <atom-sub-navigation-item active
        ><span class="link">Alerts</span></atom-sub-navigation-item
      >
      <atom-sub-navigation-item
        ><span class="link">Security</span></atom-sub-navigation-item
      >
    </atom-navigation-item>
    <atom-navigation-item>
      <span class="link" slot="title">Shipping</span>
      <span class="link" slot="icon" style="height: 1rem"
        ><atom-icon icon-id="atom:basf:send"></atom-icon
      ></span>
    </atom-navigation-item>
    <atom-navigation-item>
      <span class="link" slot="title">Irganox CSB 12</span>
      <span class="link" slot="icon" style="height: 1rem"
        ><atom-icon icon-id="atom:core:settings"></atom-icon
      ></span>
      <atom-sub-navigation-item
        ><span class="link">Hey cloud</span></atom-sub-navigation-item
      >
      <atom-sub-navigation-item
        ><span class="link">Hey cloud 2</span></atom-sub-navigation-item
      >
      <atom-sub-navigation-item
        ><span class="link">Hey cloud 3</span></atom-sub-navigation-item
      >
    </atom-navigation-item>
  </atom-navigation>
</atom-shell>

<h2>➡ Variables: colors, breakpoints, etc.</h2>
<div class="note warning">TODO</div>

<h2>➡ Header texts</h2>
<div class="note success">Works fine</div>
<h1>Title h1</h1>
<h2>Title h2</h2>
<h3>Title h3</h3>
<h4>Title h4</h4>
<h5>Title h5</h5>
<h6>Title h6</h6>

<h2>➡ Icons (optional: flags)</h2>
<div class="note success">Works fine</div>
<br />
<atom-icon icon-id="atom:core:home" color="dark-blue"></atom-icon>
<br />
<atom-icon icon-id="atom:basf:add_circle" color="dark-blue"></atom-icon>
<br />
<atom-icon icon-id="atom:core:attention" color="dark-blue"></atom-icon>

<h2>➡ Links</h2>
<div class="note success">Works fine</div>
<br />
<a href="">This is a sample link</a>

<h2>➡ Spinner</h2>
<div class="note success">Works fine</div>
<br />
<atom-progress-spinner></atom-progress-spinner>

<h2>➡ Buttons</h2>
<div class="note success">Works fine</div>
<br />

<atom-button>Primary button</atom-button>
<br />
<br />
<atom-button secondary>Secondary button</atom-button>
<br />
<br />
<atom-button>
  <atom-icon icon-id="atom:core:arrow_forward" slot="left"></atom-icon>
  Button with icon left
</atom-button>
<br />
<br />
<atom-button>
  <atom-icon icon-id="atom:core:home" slot="right"></atom-icon>
  Button with icon right
</atom-button>

<div class="note warning">TODO: create button with loading state</div>

<h2>➡ Buttongroup</h2>
<div class="note warning">In a Roadmap - April or May</div>

<h2>➡ Text Input</h2>
<div class="note warning">State tests are needed</div>
<atoms-input label="Regular" placeholder="Regular text"></atoms-input>

<h2>➡ Text Area</h2>
<div class="note warning">In a Roadmap - April or May</div>

<h2>➡ Dropdown</h2>
<div class="note warning">State tests are needed</div>
<br />
<atoms-dropdown placeholder="Select a value" has-no-default-selected="">
  <atoms-option [value]="'si'">Silver</atoms-option>
  <atoms-option [value]="'go'">Gold</atoms-option>
  <atoms-option [value]="'ir'">Iridium</atoms-option>
</atoms-dropdown>

<h2>➡ Auto-complete</h2>
<div class="note warning">State tests are needed</div>
<br />
<atom-autocomplete
  labelposition="before"
  name="combo"
  autocomplete="both"
  label="Autocomplete">
  <atoms-option [value]="'Hy'">Hydrogen</atoms-option>
  <atoms-option [value]="'He'">Helium</atoms-option>
  <atoms-option [value]="'Li'">Lithium</atoms-option>
</atom-autocomplete>

<h2>➡ Radio button</h2>
<div class="note warning">State tests are needed</div>
<br />
<atom-radio-group
  atomsControl
  name="elements"
  label="Radio Group"
  formControlName="radioGroup">
  <atom-radio-button label="Copper" [choiceValue]="'co'"></atom-radio-button>
  <atom-radio-button label="Silver" [choiceValue]="'si'"></atom-radio-button>
  <atom-radio-button label="Gold" [choiceValue]="'go'"></atom-radio-button>
</atom-radio-group>

<h2>➡ Checkbox</h2>
<div class="note warning">State tests are needed</div>
<br />
<atom-checkbox-group atomsControl label="Checkbox Group">
  <atom-checkbox
    label="Archimedes"
    [choiceValue]="'Archimedes'"
    (change)="onCheckboxChange($event)"></atom-checkbox>
  <atom-checkbox
    label="Francis Bacon"
    [choiceValue]="'Francis Bacon'"
    (change)="onCheckboxChange($event)"></atom-checkbox>
  <atom-checkbox
    label="Marie Curie"
    [choiceValue]="'Marie Curie'"
    (change)="onCheckboxChange($event)"></atom-checkbox>
</atom-checkbox-group>

<h2>➡ Date input</h2>
(Note: we can use a native html date input temporary)
<div class="note warning">TODO: Not available, on Roadmap...</div>

<h2>➡ Notifications / Alerts</h2>
Note: (Success,Info,Error,Warning)
<div class="note warning">
  TODO: Not available, on Roadmap (June => not in high priority for now).
</div>
<!-- This is a floating alert, not a notification! -->
<button (click)="showSuccessMessage = !showSuccessMessage">
  Some alert message
</button>
@if (showSuccessMessage) {
  <atom-alert
    type="success"
    displaySeconds="5"
    (close)="showSuccessMessage = false"
    verticalAlignment="top"
    horizontalAlignment="right">
    Request successfully sent
  </atom-alert>
}

<h2>➡ Card / container element</h2>
Note: (consider not using shadow - designer review needed)
<div class="note error">TODO: Not available</div>

<h2>➡ Tooltip</h2>
<div class="note warning">TODO: Not available, on Roadmap April</div>

<h2>➡ Footer</h2>
<div class="note success">Looks good</div>
<atom-footer mode="dark">
  <div slot="first">Copyright BASF SE 2022</div>
  <a href="" slot="last">Disclaimer</a>
  <a href="" slot="last">Privacy Policy</a>
  <a href="" slot="last">Terms of use</a>
  <a href="" slot="last">Imprint</a>
</atom-footer>

<h2>➡ Context menu</h2>
Note: (need to create from our side)
<div class="note error">TODO: Not available</div>

<h2>➡ Breadcrumb</h2>
<div class="note success">Lookgs good</div>
<atom-breadcrumb size="medium">
  <atom-breadcrumb-item href="#">Products</atom-breadcrumb-item>
  <atom-breadcrumb-item href="#">Ethanol 2020 DX</atom-breadcrumb-item>
  <!-- <atom-breadcrumb-item href="#">Breadcrumb Item 3</atom-breadcrumb-item>
  <atom-breadcrumb-item href="#">Breadcrumb Item 4</atom-breadcrumb-item> -->
</atom-breadcrumb>

<h2>➡ Table with sorting and pagination capabilities</h2>
Note: Optional: (In first stage, provide style guide..)
<div class="note error">TODO: Not available</div>
