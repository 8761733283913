import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { selectEvTheme } from '../ui-library.utils';

@Component({
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  selector: 'ev-portals-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ShowcaseComponent {
  public showSuccessMessage = false;

  public onChangeTheme(): void {
    selectEvTheme();
  }

  public onCheckboxChange(event: any): void {
    console.log(`[::onCheckboxChange]`, event.target.checked, event.target.value);
  }
}
