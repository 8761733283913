import { AfterViewInit, Directive, ElementRef, inject, Renderer2 } from '@angular/core';

@Directive({
  selector: '[evToggleItems]',
  standalone: true,
})
export class ToggleItemsDirective implements AfterViewInit {
  private elementRef = inject(ElementRef);
  private renderer = inject(Renderer2);

  ngAfterViewInit(): void {
    // Access the divs inside the host element using ElementRef
    const divs = this.elementRef.nativeElement.querySelectorAll(':scope > *');

    // Check if there are more than one divs
    if (divs.length > 1) {
      // Hide all divs except the first one
      for (let i = 1; i < divs.length; i++) {
        this.renderer.setStyle(divs[i], 'display', 'none');
      }

      // Create a link element
      const link = this.renderer.createElement('a');
      const linkText = this.renderer.createText(
        `+${divs.length - 1}${divs.length - 1 > 1 ? ' items' : ' item'}`,
      );
      this.renderer.appendChild(link, linkText);

      // Add a click event listener to the link to reveal all divs
      this.renderer.listen(link, 'click', () => {
        for (let i = 1; i < divs.length; i++) {
          this.renderer.setStyle(divs[i], 'display', 'block');
        }
        this.renderer.removeChild(link.parentElement, link);
      });

      // Append the link after the host element
      this.renderer.appendChild(this.elementRef.nativeElement.parentElement, link);
    }
  }
}
